import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faCheck } from "@fortawesome/free-solid-svg-icons";

export default function Section4() {
  return (
    <section className="section-4">
      <div className="row">
        <div className="col col-12 col-md-6 p-0">
          <img
            alt="section-4"
            className="section-4-img"
            src={process.env.PUBLIC_URL + "/images/landing-page/bg-3.jpg"}
          />
        </div>
        <div className="col col-12 col-md-6 p-0">
          <div className="section-4-text-div">
            <div className="section-4-text-div-inner">
              <div className="top-text-div">
                <h4 style={{ color: "#1C4884" }}>Why Choose Us</h4>
                <h2>Quality Products in Best Prices</h2>
                <p style={{ color: "#54595F", fontSize: "0.9rem" }}>
                  We provide an unparalleled experience. With a commitment to
                  quality, service, and expertise, we've earned the reputation
                  as the best in town. From cutting-edge technology to
                  time-tested essentials, our extensive selection meets every
                  need.
                </p>
              </div>
              <div className="mt-4">
                <div className="row">
                  <div className="col col-6">
                    <div
                      style={{
                        backgroundColor: "#1C4884",
                        color: "#fff",
                        borderRadius: "10px",
                        padding: "20px 30px 20px 30px",
                      }}
                    >
                      <span style={{ fontSize: "2.5rem" }}>
                        <FontAwesomeIcon icon={faThumbsUp} />
                      </span>
                      <h3 style={{ fontSize: "1.3rem" }}>
                        We Provide The Best Prices For You Loyal Customers
                      </h3>
                    </div>
                  </div>
                  <div className="col col-6">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#272727",
                            margin: "0",
                          }}
                        >
                          Money Back Guarantee
                        </p>
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#272727",
                          }}
                        >
                          90%
                        </p>
                      </div>

                      <div className="skill-bar">
                        <div
                          className="skill-track"
                          style={{ width: "90%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#272727",
                            margin: "0",
                          }}
                        >
                          Money Back Guarantee
                        </p>
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#272727",
                          }}
                        >
                          94%
                        </p>
                      </div>

                      <div className="skill-bar">
                        <div
                          className="skill-track"
                          style={{ width: "94%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="d-flex align-items-center w-75">
                        <div>
                          <span>
                            <FontAwesomeIcon icon={faCheck} color={"#1C4884"} />
                          </span>
                        </div>
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#54595F",
                            margin: "0",
                            paddingLeft: '1em'
                          }}
                        >Many Brands</p>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="d-flex align-items-center w-75 text-start">
                        <div>
                          <span>
                            <FontAwesomeIcon icon={faCheck} color={"#1C4884"} />
                          </span>
                        </div>
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#54595F",
                            margin: "0",
                            paddingLeft: '1em'
                          }}
                        >Best Quality Product</p>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="d-flex align-items-center w-75 text-start">
                        <div>
                          <span>
                            <FontAwesomeIcon icon={faCheck} color={"#1C4884"} />
                          </span>
                        </div>
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "600",
                            color: "#54595F",
                            margin: "0",
                            paddingLeft: '1em'
                          }}
                        >Commitment to Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
