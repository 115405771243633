import ReactLoading from 'react-loading'

export const LoadingMain = () => {
    return (
        <div className='main-loading'>
            <ReactLoading type="balls" color="#292933" height={20} width={50} />
        </div>
    )
}

export const LoadingSub = () => {
    return (
        <div className='loading-sub container d-flex align-items-center justify-content-center'>
            <ReactLoading type="balls" color="#292933" height={20} width={50} />
        </div>
    )
}