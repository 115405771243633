import './LandingPage.css'
import Section1 from './Section1';
import Section2 from './Section2';
import Section4 from './Section4';
import Section5 from './Section5';

export default function LandingPage() {
  return (
    <div className="landing-page-content">
      <Section1/>
      <Section2/>
      <Section4/>
      <Section5/>
    </div>
  );
}
