import PostData from "../../common/PostData";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { UseCartContext } from "./CartContext";
import DataModal from "../../common/DataModal";
import { useRef } from "react";
import { ORDER } from "../../common/ApiEndpoints";

export default function Checkout({ hideCheckout, amountData }) {
  const { handleOpenMsgModal, msgModalTitle, msgModalBody } =
    UseMsgModalContext();

  const { cartItems, cartQty, emptyCart, hideCart } = UseCartContext();

  const { customerDiscount, discountedAmount, grandTotalAfterDiscount } =
    amountData;

  const url = ORDER;

  const formRef = useRef(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if (cartItems) {
      // get the cart items and parse json to only get id and quantity, not price.
      const filteredCartItems = cartItems.map(
        ({ id, quantity }) => ({
          variant: id, // rename id to variant for api
          quantity,
        })
      );

      formData.append("ordervariant_set", JSON.stringify(filteredCartItems));
    }

    const payload = {
      payment_method: formData.get("payment_method"),
      payment_details: formData.get("payment_details"),
      ordervariant_set: JSON.parse(formData.get("ordervariant_set")), // Include filtered cart items in the payload
      estore_order: true
    };

    const token = localStorage.getItem("access_token");
    const headers = { Authorization: `JWT ${token}` };
    const result = await PostData(url, payload, headers);

    if (result.success) {
      if (result.data.status === 201) {
        handleOpenMsgModal({ title: "success", body: "Order placed." });
        emptyCart()
        hideCheckout()
        hideCart()
      }
      if (formRef.current) {
        formRef.current.reset();
      }
    } else {
      var errorList = Object.values(result.error).flat();
      var multiLineStr = errorList.join("\n");
      handleOpenMsgModal({ title: "error", body: multiLineStr });
    }
  };

  return (
    <DataModal handleCloseDataModal={hideCheckout} title={"Checkout"}>
      <div className="row">
        <div className="col col-6">
          <div className="highlighted-section">
            <div className="p-2">
              <div className="container d-flex align-items-center justify-content-between">
                <h6 className="mb-0 font-semibold text-neutral-900">Total</h6>
                <h5 className="mb-0 font-semibold text-neutral-900">
                  ${grandTotalAfterDiscount}
                </h5>
              </div>
              <hr />
              <div className="container d-flex align-items-center justify-content-between">
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  Total Quantity
                </h3>
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  {cartQty}
                </h3>
              </div>
              <div className="container d-flex align-items-center justify-content-between">
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  Variants
                </h3>
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  {cartItems.length}
                </h3>
              </div>
              <div className="container d-flex align-items-center justify-content-between">
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  Discount {customerDiscount}%
                </h3>
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  -{discountedAmount}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-6">
          <div className="highlighted-section">
            <div className="p-2 text-start">
              <form ref={formRef} onSubmit={handleFormSubmit}>
                <h3 className="text-sm mb-0 font-semibold text-neutral-900 pb-2">
                  Payment Method
                </h3>
                <div className="wrap-input">
                  <select
                    className="input"
                    name="payment_method"
                    required
                    type="text"
                  >
                    <option value={"cash_on_delivery"}>Cash on delivery</option>
                    <option value={"online"}>Online</option>
                    <option value={"cheque"}>Cheque</option>
                    <option value={"others"}>Others</option>
                  </select>
                  <span className="input-border"></span>
                </div>
                <h3 className="text-sm mb-0 font-semibold text-neutral-900 py-2">
                  Payment Details
                </h3>
                <div className="wrap-input">
                  <input
                    className="input"
                    name="payment_details"
                    type="text"
                    placeholder="Add payment details if required. eg. Cheque #"
                  />
                  <span className="input-border"></span>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <button className="app-btn" type="submit">
                    Place Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </DataModal>
  );
}
