import PostData from "../common/PostData"
import { REFRESH_TOKEN_ENDPOINT } from "../common/ApiEndpoints"

export const RefreshAccessToken = async () => {

    let refreshToken = localStorage.getItem('refresh_token')
    const payload = {
        refresh: refreshToken
    }
    const result = await PostData(REFRESH_TOKEN_ENDPOINT, payload)
    if (result.success) {
        if (result.data.status === 200) {
            localStorage.setItem('access_token', result.data.data.access)
        }

        return { success: true, msg: null }
    } else {
        return { success: false, msg: result.error }
    }
}

const parseAccessToken = () => {
    let accessToken = localStorage.getItem('access_token')
    if (!accessToken) {
        return null;
    }

    const tokenParts = accessToken.split('.');
    if (tokenParts.length !== 3) {
        // The JWT should have three parts: header, payload, and signature.
        return null;
    }

    try {
        // Decoding the payload, which is the second part of the JWT.
        const payload = JSON.parse(atob(tokenParts[1]));
        const expireTimeStamp = payload.exp
        const issuedAtTimestamp = payload.iat
        const jwtId = payload.jti
        const tokenType = payload.token_type

        return { expireTimeStamp, issuedAtTimestamp, jwtId, tokenType };
    } catch (error) {
        console.error('Error parsing access token:', error);
        return null;
    }
}

export const CheckTokenExpiration = () => {
    let accessToken = localStorage.getItem('access_token')
    // console.log('checking', accessToken)
    // Get the current Unix timestamp in seconds
    const currentTimeStamp = Math.floor(Date.now() / 1000);

    if (accessToken != null) {
        // if invalid token set exp time to 0 seconds to call the refreshAccessToken():
        const { expireTimeStamp } = parseAccessToken() || { expireTimeStamp: 0 }
        // console.log('current time', currentTimeStamp)
        // console.log('access token expire time', expireTimeStamp)
        // console.log(expireTimeStamp - currentTimeStamp)
        if (expireTimeStamp - currentTimeStamp < 30) {
            const result = RefreshAccessToken()
            result.success === true ? console.log('access token updated') : console.log('refresh token error', result.msg)
        }
    } else {
        console.log('access token invalid')
    }
}

