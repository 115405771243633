import { Link } from "react-router-dom";
import AuthNavbar from "./AuthNavbar";
import CookieConsent from "react-cookie-consent";
import React, { useState } from "react";
import { useAuth } from "./AuthContext";
import ProductList from "../home-components/product-components/ProductList";
import { FOOTER_LINK, FOOTER_TEXT, FOOTER_YEAR } from "../common/ClientData";
import LandingPage from "./landing-page-components/LandingPage";

export default function AuthParent({ img_url, title, subtitle, children }) {
const {showLogin, showProducts, showLandingPage} = useAuth()

  return (
    <div className={showProducts ? "home-container" : "auth-container"}>
      <AuthNavbar />

      {showLandingPage ? (
        <LandingPage />
      ) : (
        <>
          {showProducts && <ProductList unAuthorizedFetch={true} />}

          {showLogin && (
            <div className="row auth-parent-row">
              <div className="auth-img-col col-lg-6 d-none d-lg-block">
                <div className="auth-img-div">
                  <img className="auth-img" src={img_url} alt="side-img" />
                  <div className="px-2">
                    <h2 className="auth-img-title">{title}</h2>
                    <h6 className="auth-img-sub-title">{subtitle}</h6>
                  </div>
                </div>
              </div>
              <div className="auth-form-col col-12 col-lg-6">{children}</div>
            </div>
          )}
        </>
      )}

      <CookieConsent
        disableStyles={true}
        location="none"
        buttonText="I understand"
        buttonClasses="btn auth-form-btn"
        cookieName="ConsentCookie"
        containerClasses="cookie-consent-container"
        contentClasses="text-capitalize"
        expires={150}
      >
        <label className="responsive-label">
          This website uses cookies to enhance the user experience.
        </label>
      </CookieConsent>
      <div className="footer fixed-bottom text-center">
        <p>
          &copy; {FOOTER_YEAR}{" "}
          <Link target="_blank" to={FOOTER_LINK}>
            {FOOTER_TEXT}
          </Link>{" "}
          all rights reserved
        </p>
      </div>
    </div>
  );
}
