import ProductImage from "./ProductImage";

export default function ProductTile({ product }) {
  return (
    <div className="product-tile">
      <div className="overflow-hidden">
        <div className="product-image">
          <ProductImage imageSet={product.image_set} />
        </div>
      </div>
      <div className="product-tile-text">
        <div className="mt-2 d-flex justify-content-between p-2">
          <div>
            <h3 className="mt-1 mb-0 text-sm font-semibold text-neutral-900">
              {product.name}
            </h3>
          </div>
          <div className="text-end">
            <p className="mt-1 mb-0 tile-font text-neutral-900">
              Total Stock: {product.total_stock_count}
            </p>
            <p className="mt-1 tile-font text-neutral-900">
              Total Variants: {product.number_of_variants}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
