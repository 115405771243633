import { APP_NAME } from "../../common/ClientData";

export default function Section1() {
  return (
    <section className="section-1">
      <div className="section-1-overlay"></div>
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <div className="row ms-2 position-relative">
          <div className="col col-12 col-lg-8">
            <div className="left-tagline-div">
              <div style={{ marginBottom: "20px", marginBlockEnd: "20px" }}>
                <div style={{ margin: "0px 0px 30px 0px" }}>
                  <h1 className="heading-title">
                    Your Most Trusted Electrical Products Supplier
                  </h1>
                </div>
                <div className="section-1-desc">
                  <p>
                    At {APP_NAME}, we pride ourselves on being the
                    ultimate destination for all your electrical needs. From
                    basic components to advanced systems, we offer a wide range
                    of products to cater to every requirement. Whether you're a
                    professional electrician, a DIY enthusiast, or a business
                    owner, our extensive inventory ensures that you'll find
                    everything you need under one roof.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-4 pe-0">
            <div className="right-tagline-div">
              <div className="right-tagline-div-inner">
                <div className="right-tagline-text-div">
                  <h2>New Products Coming Soon!</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
