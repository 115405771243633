import { CUSTOMER_ENDPOINT } from "../../common/ApiEndpoints"
import { useRef, useState } from "react"
import PostData from "../../common/PostData"

export default function CustomerDetailsForm ({handleOpenMsgModal, setCustomerFormSubmitSuccess}) {
    const url = CUSTOMER_ENDPOINT
    const formRef = useRef(null)
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    const handleRegisterSubmit = async (event) => {
        event.preventDefault();
        setIsBtnDisabled(true)
        const formData = new FormData(event.target)
        const payload = {
            legal_name: formData.get('legal_name'),
            trade_name: formData.get('trade_name'),
            corp_no: formData.get('corp_no'),
            date_incorporated: formData.get('date_inorporated'),
            business_address: formData.get('business_address'),
            city_town: formData.get('city_town'),
            province: formData.get('province'),
            postal_code: formData.get('postal_code'),
            business_phone: formData.get('business_phone'),
            business_email: formData.get('business_email'),
            website_url: formData.get('website_url'),
            nature_of_business: formData.get('nature_of_business')            
        };
        
        const token = localStorage.getItem('access_token')
        const headers = { 'Authorization': `JWT ${token}` } // need to token saved wehn creating user to post. Since endpoint has IsAuthneticated permissions.
        const result = await PostData(url, payload, headers); // Await the PostData function call
        if (result.success) {
            if (result.data.status === 201) {
                handleOpenMsgModal({ title: 'success', body: 'Account created & activated.\nTo use our services please wait while we approve your customer details.\nIn the meatime login and explore our products.' })
                setCustomerFormSubmitSuccess(true)
            }
            if (formRef.current) {
                formRef.current.reset()
            }            
    
        } else {            
            var errorsList = Object.values(result.error).flat()
            var multiLineStr = errorsList.join('\n')
            handleOpenMsgModal({ title: 'error', body: multiLineStr })
        }
        setIsBtnDisabled(false)
    }

    return (
        <form ref={formRef} className='auth-form register-form' id="signup-form" onSubmit={handleRegisterSubmit}>                                
            <span className='auth-form-title p-b-37'>Register<h6>Customer Details</h6></span>
            <div className='auth-form-input-container'>                            
                <div className='wrap-input m-b-10'>
                    <input className='input' id='legal-name' name='legal_name' type="text" placeholder="legal name" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='trade-name' name='trade_name' type="text" placeholder="trade name" />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='corp-no' name='corp_no' type="text" placeholder="corporation number" />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='date-incorporated' name='date_incorporated' type="date" placeholder="date incorporated" />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <textarea className='input textarea' id='business-address' name='business_address' placeholder="business address" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='city-towm' name='city_town' type="text" placeholder="city/town" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='province' name='province' type="text" placeholder="province" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='postal-code' name='postal_code' type="text" placeholder="postal code" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='business-phone' name='business_phone' type="text" placeholder="business phone" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='business-email' name='business_email' type="email" placeholder="business email" required />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='website-url' name='website_url' type="url" placeholder="website url" />
                    <span className='input-border'></span>
                </div>
                <div className='wrap-input m-b-10'>
                    <input className='input' id='nature-of-business' name='nature_of_business' type="text" placeholder="nature of business" required />
                    <span className='input-border'></span>
                </div>
            </div>
            <div className='container-auth-form-btn p-b-112'>     
                <button disabled={isBtnDisabled} className='auth-form-btn' type="submit">{isBtnDisabled ? 'Please Wait' : 'Sign up'}</button>                       
            </div>
        </form>
    )
}