import { useEffect, useState } from "react";
import FetchData from "../../common/FetchData";
import { GET_VARIANT } from "../../common/ApiEndpoints";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { LoadingSub } from "../../common/Loading";

export default function OrderVariant({ orderVariant }) {
  const { handleOpenMsgModal } = UseMsgModalContext();
  const [orderVariantDetails, setOrderVariantDetails] = useState(null);

  let defaultProductImg =
    process.env.PUBLIC_URL + "/images/no-image-product.png";

  const [productImg, setProductImg] = useState(defaultProductImg);

  //   set the 1st image from the image set if available
  const handleSetPrductImg = (data) => {
    const imgSet = data.product["image_set"];
    if (imgSet.length > 0) {
      setProductImg(imgSet[0].product_image);
    }
  };

  const url = GET_VARIANT + orderVariant.variant; // add the variant id
  const isList = false;

  const { data, loading, error } = FetchData(url, isList);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setOrderVariantDetails(data);
      handleSetPrductImg(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleOpenMsgModal({ title: "Error", body: error.message });
    }
  }, [error, handleOpenMsgModal]);

  if (loading) {
    return <LoadingSub />;
  }

  return (
    <div className="row my-2">
      <div className="col col-3 col-md-2">
        <img className="order-variant-img" alt="variant" src={productImg}></img>
      </div>
      {orderVariantDetails && (
        <div className="col col-9 col-md-10 text-start">
          <p className="mb-0">
            <small>{orderVariantDetails.product["name"]}</small>
          </p>
          <p className="mb-0">
            <small>SKU: {orderVariantDetails.sku}</small>
          </p>
          <p className="mb-0">
            <small>Quantity: {orderVariant.quantity}</small>
          </p>
        </div>
      )}
    </div>
  );
}
