import axios from "axios"
import { USER_PROFILE_UPDATE_ENDPOINT } from "./ApiEndpoints"


const PatchData = async (type, id, payload, fileUpload = false) => {
    let url = ''

    if (type === 'user') {
        url = USER_PROFILE_UPDATE_ENDPOINT
    }   

    const token = localStorage.getItem('access_token')
    const headers = { 'Authorization': `JWT ${token}` }
    if (fileUpload) {
        headers['Content-Type'] = 'multipart/form-data'
    }

    try {
        const response = await axios.patch(url, payload, { headers: headers })
        return { success: true, data: response }
    } catch (error) {
        return { success: false, error: error.response.data }
    }
}

export default PatchData