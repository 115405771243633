import { useEffect, useState } from "react";
import FetchData from "../../common/FetchData";
import { CATEGORY_LIST } from "../../common/ApiEndpoints";


export default function FilterWithoutCollapse({setCategoryFilterList}) {

  const url = CATEGORY_LIST;
  const isList = true;

  const queryParams = {};

  var unAuthorizedFetch = true

  const [categories, setCategories] = useState([])

  const { data, loading, error, refetchData } = FetchData(url, isList, queryParams, unAuthorizedFetch);

  useEffect(() => {
    if(data) {
      console.log(data)
      setCategories(data)
    }
  }, [data])

  const [checkedValues, setCheckedValues] = useState([]);

  const handlesetCheckedValues = (value) => {
    // Create a shallow copy of the checkedValues array using the spread operator
    // This ensures that modifications to newCheckedValues won't affect the original checkedValues array
    const newCheckedValues = [...checkedValues];

    // Remove value from new checked values array if already checked (found in checked values array)
    if (checkedValues.includes(value)) {
      const index = newCheckedValues.indexOf(value);
      newCheckedValues.splice(index, 1);
    }
    // Add value if not already checked
    else {
      newCheckedValues.push(value);
    }

    setCheckedValues(newCheckedValues)

    // make the array of checked values into a space separated string
    const searchValueString = newCheckedValues.join(' ');
    setCategoryFilterList(newCheckedValues)
  };

  return (
    <div className={'text-center filter-options visible'}>
      
        <h3 className="text-sm mb-0 font-semibold text-neutral-900">
          Category
        </h3>
      
      <div>
        {categories && categories.length > 0 ?(
          categories.map((category) => (
            <div className="form-check" key={category.id}>
              <input
                className="form-check-input"
                type="checkbox"
                value={category.id}
                id={category.id}
                onChange={() => handlesetCheckedValues(category.id)}
              />
              {/* since we are using the label as the id we are removing any spaces from the label */}
              <label
                className="text-sm form-check-label"
                htmlFor={category.id}
              >
                {category.name}
              </label>
            </div>
          ))
        )
          : <p>No categories available</p>
        }
        
      </div>
    </div>
  );
}
