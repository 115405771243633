import { useState } from "react"
import ProfileView from "./ProfileView"
import CustomerView from "./CustomerView"
import EditProfileForm from "./EditProfileForm"
import DataModal from "../../common/DataModal"
import { Tooltip } from "react-tooltip"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';


export default function Profile({ setShowProfile }) {
    const [showForm, setShowForm] = useState(false)

    const handleCloseDataModal = () => {
        setShowProfile(false)
    }

    const userData = {
        firstName: localStorage.getItem('first_name'),
        lastName: localStorage.getItem('last_name'),
        email: localStorage.getItem('email'),
        userImg: localStorage.getItem('user_img'),
        dateJoined: localStorage.getItem('date_joined')
    }

    return (
        <DataModal title={!showForm ? 'Profile' : 'Edit Profile'} handleCloseDataModal={handleCloseDataModal}>
            <div className="modal-nav-div justify-content-center">
                <button data-tooltip-id="tooltip-edit-profile" data-tooltip-content="edit" data-tooltip-place="left" onClick={() => setShowForm(true)} className="mx-1">
                    <span>
                        <FontAwesomeIcon icon={faEdit} size="xs"/>
                    </span>
                </button>
            </div>

            {showForm ? <EditProfileForm userData={userData} setShowForm={setShowForm} /> :
                <>
                    <ProfileView userData={userData} />
                    <CustomerView/>
                </>
                
            }
            <Tooltip id="tooltip-edit-profile" style={{ backgroundColor: "#313131", color: "#fff", fontSize: '9px', padding: '0.3rem' }} />
        </DataModal>
    )
}