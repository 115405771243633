import '../AuthComponents.css'
import PostData from '../../common/PostData';
import { useEffect, useRef, useState } from "react";
import { REGISTER_ENDPOINT }  from '../../common/ApiEndpoints';


export default function UserDetailsForm({ handleOpenMsgModal, setUserFormSubmitSuccess }) {    
    const formRef = useRef(null)
    const url = REGISTER_ENDPOINT

    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    const handleRegisterSubmit = async (event) => {
        event.preventDefault();
        setIsBtnDisabled(true)
        const formData = new FormData(event.target)
        const payload = {
            first_name: formData.get('first_name'),
            last_name: formData.get('last_name'),
            position: formData.get('position'),
            email: formData.get('email'),
            password: formData.get('password'),
            user_img: formData.get('user_img'),                
        };
    
        
        const headers = { 'Content-Type': 'multipart/form-data' } // header to allow file posting
        const result = await PostData(url, payload, headers); // Await the PostData function call
        if (result.success) {            
            if (result.data.status === 201) {
                localStorage.setItem('refresh_token', result.data.data.refresh) // save the refresh token provided in the response (will be required for posting the customer details)
                localStorage.setItem('access_token', result.data.data.access) // save the access token provided in the response (will be required for posting the customer details)               
                handleOpenMsgModal({ title: 'success', body: 'User details successfully added, please add customer details.' })
                setUserFormSubmitSuccess(true)
            }
            if (formRef.current) {
                formRef.current.reset()
            }            
    
        } else {            
            var errorsList = Object.values(result.error).flat()
            var multiLineStr = errorsList.join('\n')
            handleOpenMsgModal({ title: 'error', body: multiLineStr })
        }
        setIsBtnDisabled(false)
    }

    // info msg:
    useEffect(() => {
        handleOpenMsgModal({ title: 'info', body: 'If you already have an account, please log in and add new users from settings.' })
    },[])

    return(
        <form ref={formRef} className='auth-form register-form' id="signup-form" onSubmit={handleRegisterSubmit}>                                                          
            <span className='auth-form-title p-b-37'>Register<h6>User Details</h6></span>
            <div className='wrap-input m-b-10'>
                <input className='input' id='first-name' name='first_name' type="text" placeholder="first name" required />
                <span className='input-border'></span>
            </div>
            <div className='wrap-input m-b-10'>
                <input className='input' id='last-name' name='last_name' type="text" placeholder="last name" required />
                <span className='input-border'></span>
            </div>
            <div className='wrap-input m-b-10'>
                <input className='input' id='position' name='position' type="text" placeholder="position" required />
                <span className='input-border'></span>
            </div>
            <div className='wrap-input m-b-10'>
                <input className='input' type="email" name='email' id="email" placeholder="email" required />
                <span className='input-border'></span>
            </div>
            <div className='wrap-input m-b-10'>
                <input className='input' type="password" id="password" name='password' placeholder="password" required />
                <span className='input-border'></span>
            </div>
            <div className='wrap-input m-b-25'>
                <div className='input' style={{height: '45px'}}>
                    <label className='responsive-label'>profile image</label>
                    <input className='form-control' type="file" id="user-img" name='user_img' accept="image/jpeg"/>
                </div>
                <span className='input-border'></span>
            </div>
            <div className='container-auth-form-btn'>
                <button disabled={isBtnDisabled} className='auth-form-btn' type="submit">{isBtnDisabled ? 'Please wait' : 'Continue'}</button>            
            </div>
        </form>
    )

}





    








