import '../AuthComponents.css'
import PostData from '../../common/PostData'
import { useState, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import AuthParent from '../AuthParent'
import { UseMsgModalContext } from '../../common/MsgModalContext'
import { PASSWORD_RESET_CONFIRM_ENDPOINT } from '../../common/ApiEndpoints'

export default function PasswordResetConfrim() {
    const {handleOpenMsgModal} = UseMsgModalContext()
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const url = PASSWORD_RESET_CONFIRM_ENDPOINT;
    const formRef = useRef(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsBtnDisabled(true);
        const formData = new FormData(event.target);
        const payload = {
            uid: uid,
            token: token,
            new_password: formData.get('new_password')
        };
        const result = await PostData(url, payload, null);
        if (result.success) {
            if (result.data.status === 204) {
                handleOpenMsgModal({ title: 'success', body: 'Password successfully reset.' })
            }
        } else {
            var errorList = Object.values(result.error).flat()
            var multiLineStr = errorList.join('\n')
            handleOpenMsgModal({ title: 'error', body: multiLineStr })
        }
        setIsBtnDisabled(false)
        navigate('/')
    };


    return (
        <AuthParent img_url={process.env.PUBLIC_URL + '/images/password-logo.png'}>
            <div className="wrap-form p-l-55 p-r-55 p-t-80 p-b-30">
                <form className='auth-form' ref={formRef} id='resend-form' onSubmit={handleFormSubmit}>
                    <span className='auth-form-title p-b-37'>Password Reset</span>
                    <div className='wrap-input m-b-25'>
                        <input className='input' type="password" name='new_password' placeholder="new password" required />
                        <span className='input-border'></span>
                    </div>
                    <div className='container-auth-form-btn'>
                        <button disabled={isBtnDisabled} type="submit" className='auth-form-btn'>{isBtnDisabled? 'Please wait' : 'Reset'}</button>
                    </div>
                    <div className='text-center p-t-57'>
                        <Link to="/">Sign In</Link>
                    </div>
                </form>
            </div>
        </AuthParent>
    )
}
