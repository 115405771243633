import { useRef } from "react"
import PatchData from "../../common/PatchData"
import { useAuth } from "../../authentication-components/AuthContext"
import { UseMsgModalContext } from "../../common/MsgModalContext"
import { Tooltip } from "react-tooltip"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCheck } from '@fortawesome/free-solid-svg-icons';


export default function EditProfileForm({ userData, setShowForm }) {
  const { setProfileData } = useAuth()
  const { handleOpenMsgModal } = UseMsgModalContext()

  const formRef = useRef(null)

  const imgUploadTrigger = () => {
    document.getElementById('img-upload').click()
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const payload = {
      first_name: formData.get('first_name'),
      last_name: formData.get('last_name'),      
      user_img: formData.get('user_img')
    }
    const fileUpload = true
    const id = null
    const result = await PatchData('user', id, payload, fileUpload)
    if (result.success) {
      handleOpenMsgModal({ title: 'success', body: 'Profile has been updated.' })
      const result = await setProfileData()
      if (result) {
        console.log('profile updated in local storage')
      }

    } else {
      var errorsList = Object.values(result.error).flat()
      var multiLineStr = errorsList.join('\n')
      handleOpenMsgModal({ title: 'error', body: multiLineStr })
    }
  }

  return (
    <div className="highlighted-section">
      <form ref={formRef} onSubmit={handleFormSubmit}>
        {userData.socialAccount === 'true' ? '' :
          <div className="edit-bg-div">
            {/* profile pic */}
            <div className="d-flex p-1 text-start align-items-center">
              <div className="col col-6 col-xl-4">
                <label className="responsive-label"><strong>User Image</strong></label>
              </div>
              <div className="col col-6 col-xl-8">
                <div className="profile-img-div text-center">
                  <input type="file" id="img-upload" className="input" name='user_img' accept="image/jpeg" hidden />
                  <img src={userData.userImg} alt="profile" style={{ height: '40px', width: '40px' }} onClick={imgUploadTrigger} />
                  <small className="px-2" style={{ fontSize: '9px' }}>Click to change</small>
                </div>
              </div>
            </div>
            {/* password */}
            <div className="d-flex p-1 text-start align-items-center">
              <div className="col col-6 col-xl-4">
                <label className="responsive-label"><strong>Password</strong></label>
              </div>
              <div className="col col-6 col-xl-8">
                <small style={{ fontSize: '9px' }}><i>To change password, logout and click 'Forgot password' in the login screen.</i></small>
              </div>
            </div>
            {/* first name */}
            <div className="d-flex p-1 text-start align-items-center">
              <div className="col col-6 col-xl-4">
                <label className="responsive-label"><strong>fist name *</strong></label>
              </div>
              <div className="col col-6 col-xl-8">
                <div className="wrap-input">
                  <input className="input" name="first_name" required type="text" defaultValue={userData.firstName} />
                  <span className='input-border'></span>
                </div>
              </div>
            </div>

            {/* last name */}
            <div className="d-flex p-1 text-start align-items-center">
              <div className="col col-6 col-xl-4">
                <label className="responsive-label"><strong>last name *</strong></label>
              </div>
              <div className="col col-6 col-xl-8">
                <div className="wrap-input">
                  <input className="input" name="last_name" required type="text" defaultValue={userData.lastName} />
                  <span className='input-border'></span>
                </div>
              </div>
            </div>
            
            <small style={{ fontSize: '9px' }}><i>Fields marked with * are mandatory.</i></small>
            <div className="pt-1 modal-nav-div justify-content-center">
              <button data-tooltip-id="tooltip-yes-profile" data-tooltip-content="edit" data-tooltip-place="left" className="mx-1" type="submit">
                <span>
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                </span>
              </button>
              <button data-tooltip-id="tooltip-cancel-profile" data-tooltip-content="cancel" data-tooltip-place="right" className="mx-1" onClick={() => setShowForm(false)} type="button">
                <span>
                  <FontAwesomeIcon icon={faClose} size="xs" />
                </span>
              </button>
            </div>
            <Tooltip id="tooltip-yes-profile" style={{ backgroundColor: "#313131", color: "#fff", fontSize: '9px', padding: '0.3rem' }} />
            <Tooltip id="tooltip-cancel-profile" style={{ backgroundColor: "#313131", color: "#fff", fontSize: '9px', padding: '0.3rem' }} />
          </div>

        }

      </form>

    </div>
  )
}