import { useEffect, useState } from "react";
import FetchData from "../../common/FetchData";
import { CUSTOMER_ENDPOINT } from "../../common/ApiEndpoints";
import { LoadingSub } from "../../common/Loading";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function CustomerView() {
  const { handleOpenMsgModal } = UseMsgModalContext();

  const [customerData, setCustomerData] = useState({});

  const url = CUSTOMER_ENDPOINT;
  const isList = false;

  
  const { data, loading, error} = FetchData(url, isList);
  

  useEffect(() => {    
    if (data && data.length > 0) {      
      setCustomerData(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleOpenMsgModal({ title: "error", body: error.message });
    }
  }, [error, handleOpenMsgModal]);

  if (loading) {
    return <LoadingSub />;
  }

  return (
    <>
      <div className="highlighted-section">
        <div className="d-flex p-1 text-start align-items-center">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Legal Name</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.legal_name}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Trade Name</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.trade_name}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Corporation No</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">{customerData.corp_no}</label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Date Incorporated</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.date_incorporated}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Business Address</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.business_address}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>City/Towm</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">{customerData.city_town}</label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Province</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">{customerData.province}</label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Postal Code</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.postal_code}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Business Phone</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.business_phone}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Business Email</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.business_email}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Website Url</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.website_url}
            </label>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Nature of Business</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.nature_of_business}
            </label>
          </div>
        </div>
      </div>
      <div className="highlighted-section">
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Is Approved</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <div className="icon">
              <span>
                <FontAwesomeIcon
                  icon={customerData.is_approved ? faCheck : faTimes}
                  color={customerData.is_approved ? "#28a745" : "#dc3545"}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex p-1 text-start">
          <div className="col col-6 col-xl-4">
            <label className="responsive-label">
              <strong>Customer Discount</strong>
            </label>
          </div>
          <div className="col col-6 col-xl-8">
            <label className="responsive-label">
              {customerData.customerdiscount} %
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
