import '../AuthComponents.css'
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import AuthParent from '../AuthParent';
import { UseMsgModalContext } from '../../common/MsgModalContext';
import PrivacyPolicyData from '../PrivacyPolicyData';

import UserDetailsForm from './UserDetailsForm';
import CustomerDetailsForm from './CustomerDetailsForm';


export default function RegisterForms () {
    const { handleOpenMsgModal } = UseMsgModalContext()    
    const [showUserForm, setShowUserForm] = useState(true)    
    const [showCustomerForm,setShowCustomerForm] = useState(false)    

    const [userFormSubmitSucess, setUserFormSubmitSuccess] = useState(false)
    const [customerFormSubmitSucess, setCustomerFormSubmitSuccess] = useState(false)
    

    const navigate = useNavigate(null);

    useEffect(() => {
        if(userFormSubmitSucess) {
            setShowUserForm(false)
            setShowCustomerForm(true)
        }
    }, [userFormSubmitSucess])
    

    useEffect(() => {
        if(customerFormSubmitSucess) {
            // remove tokens & return to login page:
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('access_token')
            navigate('/')
        }
    })


    const showPrivacyPolicy = () => {
        handleOpenMsgModal({ title: 'Privacy Policy', body: <PrivacyPolicyData />, makeLarge: true })
    }

    return (
        <AuthParent img_url={process.env.PUBLIC_URL + '/images/register-logo.png'}>
            <div className="wrap-form p-l-55 p-r-55 p-t-80 p-b-30"> 
                
                {showUserForm &&                    
                    <UserDetailsForm handleOpenMsgModal={handleOpenMsgModal} setUserFormSubmitSuccess={setUserFormSubmitSuccess}/>                                 
                }

                {showCustomerForm &&
                    <CustomerDetailsForm handleOpenMsgModal={handleOpenMsgModal} setCustomerFormSubmitSuccess={setCustomerFormSubmitSuccess}/>                      
                }

                <div className='text-center'>                
                    <Link to="/">Log in</Link>
                </div>

                <div className='text-center'>
                    <small style={{ fontSize: '9px' }}>By registering up you are agreeing to our<Link onClick={showPrivacyPolicy} style={{ fontSize: '9px' }}> Terms & Privacy Policy</Link></small>
                </div>     
            </div>            
        </AuthParent>
    )
}

