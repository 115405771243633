import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faAdd,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { UseCartContext } from "../cart-components/CartContext";
import { LoadingMain } from "../../common/Loading";

export default function Variant({ variant }) {
  const { cartItems, increaseCartQty, decreaseCartQty, getCartItemQty } =
    UseCartContext();

  let cartItemQty = 0;

  let stockCount = variant.get_total_stock_count ?? 0; // if null then set to 0
  let available = false;
  if (variant) {
    cartItemQty = getCartItemQty(variant.id);

    const showToastMessage = () => {
      if (variant) {
        toast.success(`${variant.name} SKU:${variant.sku} added to cart.`);
      }
    };

    const handleIncreaseCartQty = () => {
      increaseCartQty(variant);
      if (cartItems.find((item) => item.id === variant.id) === undefined) {
        showToastMessage();
      }
    };

    available = variant.available;
    return (
      <>
        <tr key={variant.id}>
          <td>{variant.name}</td>
          <td>{variant.sub_category_str}</td>
          <td>{variant.sku}</td>
          <td>{variant.price}</td>
          <td>{variant.weight}</td>
          <td>{variant.min_quantity}</td>
          <td>{variant.max_quantity}</td>
          <td>{stockCount}</td>
          <td>
            <FontAwesomeIcon
              icon={available ? faCheck : faTimes}
              color={available ? "#28a745" : "#dc3545"}
            />
          </td>

          <td>
            <button
              disabled={
                stockCount === 0 || !available || cartItemQty === 0
                  ? true
                  : false
              }
              data-tooltip-id="tooltip-remove"
              data-tooltip-content="deduct"
              data-tooltip-place="left"
            >
              <FontAwesomeIcon
                icon={faMinus}
                onClick={() => decreaseCartQty(variant.id)}
              />
            </button>
            {cartItemQty}
            <button
              disabled={
                stockCount === 0 || !available || cartItemQty === stockCount
                  ? true
                  : false
              }
              data-tooltip-id="tooltip-add"
              data-tooltip-content="add"
              data-tooltip-place="right"
            >
              <FontAwesomeIcon icon={faAdd} onClick={handleIncreaseCartQty} />
            </button>
          </td>
        </tr>
      </>
    );
  }
  return <LoadingMain />;
}
