import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './common/ProtectedRoute';
import LoginForm from './authentication-components/login-components/LoginForm';
import RegisterForms from './authentication-components/register-components/RegisterForms';
import PasswordReset from './authentication-components/password-reset-components/PasswordReset';
import PasswordResetConfrim from './authentication-components/password-reset-components/PasswordResetConfirm';
import Dashboard from './home-components/Dashboard';

export default function AppRoutes() {

    return (    
      <Routes>     
        {/* auth routes: */}
        <Route path={"/"} element={<LoginForm />} />        
        <Route path='/register' element={<RegisterForms />} />
        <Route path='/password-reset' element={<PasswordReset />} />
        <Route path='/password-reset/:uid/:token' element={<PasswordResetConfrim />} />  
        
        {/* home routes: */}
        <Route path="/home" element={ProtectedRoute({element:<Dashboard/>})} />
      </Routes>
    )
  }