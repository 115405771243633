import { useEffect, useState } from "react";
import DataModal from "../../common/DataModal";
import { UseCartContext } from "./CartContext";
import { toast } from "react-toastify";
import CartItem from "./CartItem";
import FetchData from "../../common/FetchData";
import { CUSTOMER_ENDPOINT } from "../../common/ApiEndpoints";
import Checkout from "./Checkout";

export default function Cart() {
  const {
    showCart,
    hideCart,
    emptyCart,
    cartIsOpen,
    cartItems,
    cartQty,
    getCartTotal,
  } = UseCartContext();

  const [showCheckOut, setShowCheckOut] = useState(false);

  const handleSetShowCheckout = () => {
    hideCart();
    setShowCheckOut(true);
  };

  const handleHideCheckout = () => {
    setShowCheckOut(false);
    showCart();
  };

  const [customerDiscount, setCustomerDiscount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [grandTotalAfterDiscount, setGrandTotalAfterDiscount] = useState(0);
  const [customerIsApproved, setCustomerIsApproved] = useState(false);
  const [amountData, setAmountData] = useState({});

  const calculateDiscountedAmount = () => {
    const cartTotal = getCartTotal();
    const discountedAmount = cartTotal * (customerDiscount / 100);
    setDiscountedAmount(discountedAmount.toFixed(2));
  };

  const caculateGrandTotalAfterDiscount = () => {
    const cartTotal = getCartTotal();
    const grandTotalAfterDiscount = cartTotal - discountedAmount;
    setGrandTotalAfterDiscount(grandTotalAfterDiscount.toFixed(2));
  };

  useEffect(() => {
    setAmountData({
      customerDiscount,
      discountedAmount,
      grandTotalAfterDiscount,
    });
  }, [customerDiscount, discountedAmount, grandTotalAfterDiscount]);

  const handleEmptyCart = () => {
    emptyCart();
    showToastMessage1();
  };

  const showToastMessage = () => {
    toast.error("Customer not approved.");
  };

  const showToastMessage1 = () => {
    toast.info("Cart cleared.");
  };

  const url = CUSTOMER_ENDPOINT;
  const isList = false;
  const { data } = FetchData(url, isList);

  useEffect(() => {
    if (data && data.length > 0) {
      setCustomerDiscount(data[0]["customerdiscount"]);
      setCustomerIsApproved(data[0]["is_approved"]);
      calculateDiscountedAmount();
      caculateGrandTotalAfterDiscount();
    }
  }, [data, cartQty, customerDiscount, discountedAmount]);

  useEffect(() => {
    if (cartIsOpen && !customerIsApproved) {
      showToastMessage();
      hideCart();
    }
  }, [cartIsOpen, customerIsApproved]);

  return (
    <>
      {cartIsOpen && customerIsApproved && (
        <DataModal handleCloseDataModal={hideCart} title={"Cart"}>
          {cartItems.length > 0 ? (
            <>
              <div
                style={{
                  height: "70%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  textAlign: "center",
                }}
              >
                {cartItems.map((item) => (
                  <CartItem key={item.id} id={item.id} />
                ))}
                <small className="font-medium text-neutral-500 mb-0">
                  Variants: {cartItems.length}
                </small>
                <br/>
                <small className="font-medium text-neutral-500 mb-0">
                  Total Quantity: {cartQty}
                </small>
                <div className="d-flex justify-content-center my-2">
                  <button className="app-btn" onClick={handleEmptyCart}>
                    Empty Cart
                  </button>
                </div>
              </div>
              <hr />

              <div className="highlighted-section">
                <div className="m-2">
                  <div className="row">
                    <div className="col col-6 text-start">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        Total
                      </h3>
                    </div>
                    <div className="col col-6 text-end">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        ${getCartTotal()}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6 text-start">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        Customer Discount %
                      </h3>
                    </div>
                    <div className="col col-6 text-end">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        {customerDiscount}%
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6 text-start">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        Discounted Amount
                      </h3>
                    </div>
                    <div className="col col-6 text-end">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        -${discountedAmount}
                      </h3>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col col-6 text-start">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        Grand Total After Discount
                      </h3>
                    </div>
                    <div className="col col-6 text-end">
                      <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                        ${grandTotalAfterDiscount}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button
                  onClick={() => handleSetShowCheckout()}
                  className="app-btn"
                >
                  Proceed to Checkout
                </button>
              </div>
            </>
          ) : (
            <h3 className="text-sm mb-0 font-semibold text-neutral-900">
              Your cart is empty
            </h3>
          )}
        </DataModal>
      )}
      {showCheckOut && (
        <Checkout hideCheckout={handleHideCheckout} amountData={amountData} />
      )}
    </>
  );
}
