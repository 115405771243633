import { useRef, useState } from "react"
import PostData from "../../common/PostData"
import { Link } from "react-router-dom";
import AuthParent from "../AuthParent";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { PASSWORD_RESET_ENDPOINT } from "../../common/ApiEndpoints";


export default function PasswordReset() {
    const { handleOpenMsgModal } = UseMsgModalContext()
    const url = PASSWORD_RESET_ENDPOINT
    const formRef = useRef(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    const handleFormSubmit = async (event) => {
        setIsBtnDisabled(true)
        event.preventDefault();
        const formData = new FormData(event.target);
        const payload = {
            email: formData.get('email')
        };

        const result = await PostData(url, payload, null);

        if (result.success) {
            if (result.data.status === 204) {
                handleOpenMsgModal({ title: 'success', body: 'Password reset email sent to your registered email.' })
            }
            if (formRef.current) {
                formRef.current.reset()
            }
        } else {
            var errorsList = Object.values(result.error).flat()
            var multiLineStr = errorsList.join('\n')
            handleOpenMsgModal({ title: 'error', body: multiLineStr })
        }
        setIsBtnDisabled(false)
    }

    return (
        <AuthParent img_url={process.env.PUBLIC_URL + '/images/password-reset-logo.png'}>
            <div className="wrap-form p-l-55 p-r-55 p-t-80 p-b-30">
                <form className='auth-form' ref={formRef} id='resend-form' onSubmit={handleFormSubmit}>
                    <span className='auth-form-title p-b-37'>Password Reset</span>
                    <div className='wrap-input m-b-25'>
                        <input className='input' type="email" name='email' placeholder="email" required />
                        <span className='input-border'></span>
                    </div>
                    <div className='container-auth-form-btn'>
                        <button disabled={isBtnDisabled} type="submit" className='auth-form-btn'>{isBtnDisabled ? 'Please wait' : 'Send'}</button>
                    </div>
                    <div className='text-center p-t-57'>
                        <Link to="/">Sign In</Link>
                    </div>
                </form>
            </div>
        </AuthParent>
    )
}