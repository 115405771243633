import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./index.css";
import "react-toastify/ReactToastify.css";
import {ToastContainer} from 'react-toastify'
import AppRoutes from "./AppRoutes";
import MsgModal from "./common/MsgModal";
import { MsgModalProvider } from "./common/MsgModalContext";
import { AuthProvider } from "./authentication-components/AuthContext";

function App() {
  return (
    <AuthProvider>
      <MsgModalProvider>
        <MsgModal />
        <AppRoutes />
        <ToastContainer position={"top-center"}/>
      </MsgModalProvider>
    </AuthProvider>
  );
}

export default App;
