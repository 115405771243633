import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faSignOut, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from "../../authentication-components/AuthContext";

export default function MenuDropdown({handleSetShowProfile, handleSetShowOrder}) {
    const { logout } = useAuth()

    const name = localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')

    const handleLogout = () => {
        logout();
    }

    return (
        <div className="dropdown-wrapper">
            <ul className="menu-bar text-center">
                <label className="responsive-label"><strong>{name}</strong></label>
                <div className="list-div" style={{borderRadius: '25px'}}>
                    <li>
                        <Link to="#" onClick={handleSetShowProfile}>
                            <div className="row align-items-center">
                                <div className="col col-5">
                                    <div className="icon">
                                        <span>
                                            <FontAwesomeIcon icon={faTools} color={"#fff"}/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col col-7 px-0 text-start">
                                    <label className="responsive-label"><strong>Profile</strong></label>
                                </div>
                            </div>
                        </Link>
                    </li>
                </div>
                <div className="list-div" style={{borderRadius: '25px'}}>
                    <li>
                        <Link to="#" onClick={handleSetShowOrder}>
                            <div className="row align-items-center">
                                <div className="col col-5">
                                    <div className="icon">
                                        <span>
                                            <FontAwesomeIcon icon={faShoppingBag} color={"#fff"}/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col col-7 px-0 text-start">
                                    <label className="responsive-label"><strong>Orders</strong></label>
                                </div>
                            </div>
                        </Link>
                    </li>
                </div>
                <div className="list-div" style={{borderRadius: '25px'}}>
                    <li>
                        <Link onClick={handleLogout} to="#">
                            <div className="row align-items-center">
                                <div className="col col-5">
                                    <div className="icon">
                                        <span>
                                            <FontAwesomeIcon icon={faSignOut} color={"#fff"}/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col col-7 px-0 text-start">
                                    <label className="responsive-label"><strong>Log Out</strong></label>
                                </div>
                            </div>
                        </Link>
                    </li>
                </div>
            </ul>
        </div>
    )
}