import { useEffect, useState } from "react";
import axios from "axios";

const FetchData = (
  url,
  isList,
  Queryparams = {},
  unAuthorizedFetch = false,
  isFile = false
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = localStorage.getItem("access_token");

  const fetchData = async () => {
    try {
      const headers = unAuthorizedFetch || !accessToken ? {} : { Authorization: `JWT ${accessToken}` };
      const response = await axios.get(url, { headers, params: Queryparams, responseType: isFile ? 'blob' : 'json' });
      
      setData(isList ? response.data.results : response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 429) {
        error.message = `${error.response.statusText}.\n ${error.response.data.detail}`;
      }
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    fetchData();
  }, [url, unAuthorizedFetch]);
  return { data, loading, error, refetchData: fetchData };
};


export default FetchData;
