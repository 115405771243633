import { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const UseCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [])
  const [cartIsOpen, setCartisOpen] = useState(false);

  const showCart = () => setCartisOpen(true);
  const hideCart = () => setCartisOpen(false);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const getCartItemQty = (id) => {
    return cartItems.find((item) => item.id === id)?.quantity || 0; // if id found return item.quantity else 0
  };

  const increaseCartQty = (variant) => {
    setCartItems(currentItems => {
      // see if id exists, if not(undefined) crate an obj(id, price, quantity) & add to cartitems along with current items
      if (currentItems.find(item => item.id === variant.id) === undefined) {
        return [...currentItems, { id: variant.id, price: variant.price, quantity: 1 }];
      // if id exists then set the quantity of the found obj to quantity + 1
      } else {
        return currentItems.map(item => {
          if (item.id === variant.id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      }
    });
  };
  
  const decreaseCartQty = (id) => {
    setCartItems(currentItems => {
      // look for item with id & check if quantity is 1:
      if (currentItems.find(item => item.id)?.quantity === 1) {
        // if found, remove the item from the current items:
        return currentItems.filter(item => item.id !== id);
      } else {
        // if no item with id is found with quantity 1 then map thru the current items &
        // look for item with id, if found return the item with quantity - 1
        return currentItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            // if mathing id item not found, return the item.
            return item;
          }
        });
      }
    });
  };

  const removeFromCart = (id) => {
    setCartItems(currentItems => {
      return currentItems.filter(item => item.id !== id);
    });
  };

  const cartQty = cartItems.reduce(
    (quantity, item) => item.quantity + quantity, 0 // initial value is 0
  )

  const getCartTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2); // toFixed(2) = 2 decimal places
  };

  const emptyCart = () => {
    setCartItems([])
  }

  const contextValue = {
    getCartItemQty,
    increaseCartQty,
    decreaseCartQty,
    removeFromCart,
    emptyCart,
    showCart,
    hideCart,
    getCartTotal,
    cartIsOpen,
    cartItems,
    cartQty    
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
