import { createContext, useContext, useState } from "react";
import {
  VERIFY_TOKEN_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from "../common/ApiEndpoints";
import PostData from "../common/PostData";
import { useNavigate } from "react-router-dom";
import { CheckTokenExpiration, RefreshAccessToken } from "./RefreshAccessToken";
import SimpleFetch from "../common/SimpleFetch";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// Define a variable to store the interval ID
let tokenExpirationIntervalId;

export const ActivateCheckTokenExpiration = () => {
  // run function every 30 seconds:
  tokenExpirationIntervalId = setInterval(CheckTokenExpiration, 30000);
};

export const DeactivateCheckTokenExpiration = () => {
  // Use clearInterval with the stored interval ID
  clearInterval(tokenExpirationIntervalId);
};

export function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh_token")
  );
  // const isAuthenticated = !!token;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setProfileData = async () => {
    let imgSrc = process.env.PUBLIC_URL + "/images/no-image-user.png"; // initially set to default no image.png
    const result = await SimpleFetch(USER_PROFILE_ENDPOINT, null);
    if (result.success) {
      const data = result.data;

      if (data.user_img !== null) {
        imgSrc = data.user_img;
      }

      localStorage.setItem("first_name", data.first_name);
      localStorage.setItem("last_name", data.last_name);
      localStorage.setItem("email", data.email);
      localStorage.setItem("user_img", imgSrc);
      localStorage.setItem("date_joined", data.date_joined);
    }
    return result.success;
  };

  const login = async (accessToken, refreshToken) => {
    setAccessToken(accessToken);
    localStorage.setItem("access_token", accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem("refresh_token", refreshToken);
    ActivateCheckTokenExpiration();
    const result = await setProfileData();
    if (result) {
      setIsAuthenticated(true);
    } else {
      logout();
    }
  };

  const logout = () => {
    DeactivateCheckTokenExpiration();
    let storageItems = [
      "access_token",
      "refresh_token",
      "first_name",
      "last_name",
      "email",
      "user_img",
      "date_joined",
    ];
    storageItems.forEach((element) => {
      localStorage.removeItem(element);
    });
    setAccessToken(null);
    setRefreshToken(null);
    setIsAuthenticated(false);
    navigate("/");
  };

  const apiValidateToken = async (refreshToken) => {
    const payload = {
      token: refreshToken,
    };
    const result = await PostData(VERIFY_TOKEN_ENDPOINT, payload);
    if (result.success) {
      if (result.data.status === 200) {
        const result = await RefreshAccessToken();
        if (result.success) {
          console.log("access token updated");
          setIsAuthenticated(true);
        } else {
          console.log("refresh token error", result.msg);
          logout();
        }
      }
    } else {
      logout();
    }
  };

  const [showLandingPage, setShowLandingPage] = useState(true);
  const [showLogin, setShowLogin] = useState(true);
  const [showProducts, setShowProducts] = useState(false);

  const toggleShowLoginOrProducts = () => {
    setShowLogin((prev) => !prev);
    setShowProducts((prev) => !prev);
    setShowLandingPage(false);
  };

  const toggleShowLandingPage = () => {
    setShowLogin(true);
    setShowProducts(false);
    setShowLandingPage(true);
  };

  const contextValue = {
    accessToken,
    refreshToken,
    isAuthenticated,
    apiValidateToken,
    login,
    logout,
    setProfileData,
    showLandingPage,
    setShowLandingPage,
    showLogin,
    setShowLogin,
    showProducts,
    setShowProducts,
    toggleShowLoginOrProducts,
    toggleShowLandingPage,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
