import { useState, useEffect } from "react";
import FetchData from "../../common/FetchData";
import { ORDER } from "../../common/ApiEndpoints";
import DataModal from "../../common/DataModal";
import OrderItem from "./OrderItem";
import { LoadingMain } from "../../common/Loading";
import { UseMsgModalContext } from "../../common/MsgModalContext";

export default function Order({ setShowOrder }) {
  const { handleOpenMsgModal } = UseMsgModalContext();

  const [orderData, setOrderData] = useState([]);
  const [nextPage, setNextPage] = useState(null);

  const handleCloseDataModal = () => {
    setShowOrder(false);
  };

  const url = ORDER;
  const isList = false;

  const { data, loading, error } = FetchData(url, isList);
  useEffect(() => {    
    if (data && Object.keys(data).length > 0) {
      
      // Filter out data with duplicate dates, because get api is called twice

      // const filteredData = data.results.filter(
      //   (result) => !orderData.some((item) => item.id === result.id)
      // );
      // setOrderData((prevOrderData) => prevOrderData.concat(filteredData));
      // data.next ? setNextPage(data.next) : setNextPage(null);
      setOrderData(data)
    }
    
    
  }, [data]);

  useEffect(() => {
    if (error) {
      handleOpenMsgModal({ title: "Error", body: error.message });
    }
  }, [error]);

  if (loading) {
    return <LoadingMain />;
  }

  return (
    <DataModal
      title={"Orders"}
      handleCloseDataModal={handleCloseDataModal}
      widerModal={true}
    >
      <small>
        <i style={{ fontSize: "9px" }}>
          Orders of all users of the customer will be listed.
        </i>
      </small>
      {orderData.length > 0 ? (
        orderData.map((order) => <OrderItem key={order.id} order={order} />)
      ) : (
        <h3 className="text-sm mb-0 font-semibold text-neutral-900">
          No orders found
        </h3>
      )}
    </DataModal>
  );
}
