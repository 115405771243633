export default function ProfileView({ userData }) {
    return (
        <div className="highlighted-section">
            <div className="d-flex p-1 text-start align-items-center">
                <div className="col col-6 col-xl-4">
                    <label className="responsive-label"><strong>User Image</strong></label>
                </div>
                <div className="col col-6 col-xl-8">
                    <div className="profile-img-div profile-img-div-no-hover text-center">
                        <img src={userData.userImg} alt="profile" style={{ height: '40px', width: '40px' }} />
                    </div>
                </div>
            </div>
            <div className="d-flex p-1 text-start">
                <div className="col col-6 col-xl-4">
                    <label className="responsive-label"><strong>First Name</strong></label>
                </div>
                <div className="col col-6 col-xl-8">
                    <label className="responsive-label">{userData.firstName}</label>
                </div>
            </div>
            <div className="d-flex p-1 text-start">
                <div className="col col-6 col-xl-4">
                    <label className="responsive-label"><strong>Last Name</strong></label>
                </div>
                <div className="col col-6 col-xl-8">
                    <label className="responsive-label">{userData.lastName}</label>
                </div>
            </div>
            <div className="d-flex p-1 text-start">
                <div className="col col-6 col-xl-4">
                    <label className="responsive-label"><strong>Email</strong></label>
                </div>
                <div className="col col-6 col-xl-8">
                    <label className="responsive-label">{userData.email}</label>
                </div>
            </div>            
            <div className="d-flex p-1 text-start">
                <div className="col col-6 col-xl-4">
                    <label className="responsive-label"><strong>Date Joined</strong></label>
                </div>
                <div className="col col-6 col-xl-8">
                    <label className="responsive-label">{userData.dateJoined}</label>
                </div>
            </div>
        </div>
    )
}