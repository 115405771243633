import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  APP_NAME,
  CUSTOMER_SUPPORT_PHONE,
  EMAIL,
} from "../../common/ClientData";

export default function Section5() {
  return (
    <section className="section-5">
      <div className="section-5-overlay"></div>
      <div style={{ margin: "auto", maxWidth: "1170px", position: "relative" }}>
        <section style={{ marginBottom: "70px", padding: "2em 0" }}>
          <div className="d-flex align-items-center justify-content-around">
            {/* useful links */}
            <div>
              <h4 style={{ fontSize: "1.5em", color: "#fff", fontWeight: "600" }}>
                Useful Links
              </h4>
              <div className="text-start">
                <Link className="section-5-link" to={"#"}>
                  Privacy Policy
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  Terms and Conditions
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  Disclaimer
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  Support
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  FAQ
                </Link>
              </div>
            </div>
            {/* center with company logo */}
            <div className="text-center">
              <div className="w-50 m-auto">
                <img
                  src={process.env.PUBLIC_URL + "/images/app-logo.png"}
                  width="100"
                  height="100"
                  className="d-inline-block align-top mb-2"
                  alt=""
                />
                <p style={{ color: "#ACACAC"}}>
                  {APP_NAME} is your premier destination for cutting-edge
                  electrical solutions. With a focus on innovation and
                  expertise, we provide top-quality electrical services tailored
                  to meet your needs
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    style={{ fontSize: "2.5rem", padding: "10px" }}
                    to={"#"}
                  >
                    <FontAwesomeIcon icon={faFacebook} color={"#fff"} />
                  </Link>
                  <Link
                    style={{ fontSize: "2.5rem", padding: "10px" }}
                    to={"#"}
                  >
                    <FontAwesomeIcon icon={faInstagram} color={"#fff"} />
                  </Link>
                </div>
              </div>
            </div>
            {/* quick links */}
            <div className="text-end">
              <h4 style={{ fontSize: "1.5em", color: "#fff", fontWeight: "600" }}>
                Quick Links
              </h4>
              <div>
                <Link className="section-5-link" to={"#"}>
                  About Us
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  Shop
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  Product Details
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  FAQ
                </Link>
                <br />
                <Link className="section-5-link" to={"#"}>
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section style={{padding: "2em 0" }}>
          <div className="d-flex align-items-center justify-content-center">
            {/* address */}
            <div className="d-flex container justify-content-center" style={{ fontSize: "1rem" }}>
              <span className="pe-2">
                <FontAwesomeIcon color="#fff" icon={faMapPin} />
              </span>
              <p style={{ color: "#ACACAC", fontWeight: "400" }}>
                238 Advance Blvd, Brampton, ON
              </p>
            </div>
            {/* mail */}
            <div className="d-flex container justify-content-center" style={{ fontSize: "1rem" }}>
              <span className="pe-2">
                <FontAwesomeIcon color="#fff" icon={faEnvelope} />
              </span>
              <p style={{ color: "#ACACAC", fontWeight: "400" }}>{EMAIL}</p>
            </div>
            {/* phone */}
            <div className="d-flex container justify-content-center" style={{ fontSize: "1rem" }}>
              <span className="pe-2">
                <FontAwesomeIcon color="#fff" icon={faPhone} />
              </span>
              <p style={{ color: "#ACACAC", fontWeight: "400" }}>{CUSTOMER_SUPPORT_PHONE}</p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
