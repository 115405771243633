import SimpleImageSlider from "react-simple-image-slider";

export default function ProductImage({ imageSet, imageSize }) {
  let width = 150
  let height = 150

  if(imageSize === 'large')  {    
    width = 300
    height = 300
  }

  const images = [];

  if (imageSet.length > 0) {
    // clear images array:
    images.length = 0;
    imageSet.forEach((element) => {
      images.push({ url: element.product_image });
    });
  } else {
    images.push({
      url: process.env.PUBLIC_URL + "/images/no-image-product.png",
    });
  }

  return (
    <SimpleImageSlider
      width={width}
      height={height}
      bgColor={"rgba(0, 0, 0, 0)"}
      images={images}
      showBullets={imageSet.length > 1 ? true : false}
      showNavs={imageSet.length > 1 ? true : false}
      autoPlay={imageSet.length > 1 ? true : false}
      style={{ borderRadius: "10px" }}
    />
  );
}
