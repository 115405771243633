// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../authentication-components/AuthContext';

function ProtectedRoute({ element }) {
  const { isAuthenticated } = useAuth();
    
  if (!isAuthenticated) {
    return <Navigate to='/' />
  }

  return element
}

export default ProtectedRoute;
