import '../AuthComponents.css'
import PostData from '../../common/PostData';
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthParent from '../AuthParent';
import { UseMsgModalContext } from '../../common/MsgModalContext';
import { LOGIN_ENDPOINT } from '../../common/ApiEndpoints';
import { useAuth, ActivateCheckTokenExpiration } from '../AuthContext';
import PrivacyPolicyData from '../PrivacyPolicyData';
import { APP_NAME, APP_TAG } from '../../common/ClientData';


export default function LoginForm() {
    const { handleOpenMsgModal } = UseMsgModalContext()
    const url = LOGIN_ENDPOINT
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const navigate = useNavigate()

    const { isAuthenticated, refreshToken, apiValidateToken, login } = useAuth()

    const formRef = useRef(null);

    useEffect(() => {
        if (refreshToken) {
            apiValidateToken(refreshToken)
        }
    }, [refreshToken])

    // Check isAuthenticated only once and navigate to home if authenticated
    useEffect(() => {
        if (isAuthenticated) {
            ActivateCheckTokenExpiration()
            navigate('/home');
        }
    }, [isAuthenticated, navigate]);

    const handleFormSubmit = async (event) => {
        setIsBtnDisabled(true)
        event.preventDefault();
        const formData = new FormData(event.target);
        const payload = {
            email: formData.get('email'),
            password: formData.get('password')
        };

        const result = await PostData(url, payload, null);
        if (result.success) {
            if (result.data.status === 200) {
                const accessToken = result.data.data.access
                const refreshToken = result.data.data.refresh
                await login(accessToken, refreshToken)
            }
            if (formRef.current) {
                formRef.current.reset()
            };
            navigate('/home')
        } else {
            var errorsList = Object.values(result.error).flat()
            var multiLineStr = errorsList.join('\n')
            handleOpenMsgModal({ title: 'error', body: multiLineStr })
        }
        setIsBtnDisabled(false)
    };


    const showPrivacyPolicy = () => {
        handleOpenMsgModal({ title: 'Privacy Policy', body: <PrivacyPolicyData />, makeLarge: true })
    }

    return (
        <AuthParent img_url={process.env.PUBLIC_URL + '/images/app-logo.png'} title={APP_NAME} subtitle={APP_TAG}>
            <div className="wrap-form p-l-55 p-r-55 p-t-80 p-b-30">
                <form ref={formRef} className='auth-form' onSubmit={handleFormSubmit}>
                    <span className='auth-form-title p-b-37'>Log In</span>
                    <div className='wrap-input m-b-20'>
                        <input className='input' type="email" name='email' placeholder="email" required />
                        <span className='input-border'></span>
                    </div>
                    <div className='wrap-input m-b-25'>
                        <input className='input' type="password" name='password' placeholder="password" required />
                        <span className='input-border'></span>
                    </div>
                    <div className='container-auth-form-btn p-b-112'>
                        <button className='auth-form-btn' disabled={isBtnDisabled} type='submit'>{isBtnDisabled ? 'Please wait' : 'Log in'}</button>
                    </div>
                    <div className='text-center'>
                        <Link to="/password-reset">Forgot Password?</Link>
                        <br />
                        <Link to="/register">Register</Link>
                    </div>
                    <div className='text-center'>
                        <small style={{ fontSize: '9px' }}>By registering you are agreeing to our<Link onClick={showPrivacyPolicy} style={{ fontSize: '9px' }}> Terms & Privacy Policy</Link></small>
                    </div>
                </form>
            </div>
        </AuthParent>
    )
}
