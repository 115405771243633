import { useEffect } from "react";
import './common.css'
import { UseMsgModalContext } from "./MsgModalContext";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';


export default function MsgModal() {
    // using use context instead of passing props directly:
    const { openMsgModal, handleCloseMsgModal, msgModalTitle, msgModalBody, makeLargeVal } = UseMsgModalContext()
    useEffect(() => {
        // Disable scrolling when the component is mounted
        document.body.style.overflow = 'hidden';

        return () => {
            // Enable scrolling when the component is unmounted
            document.body.style.overflow = 'auto';
        }
    }, []);

    return (
        openMsgModal && (
            <div className="modal-background">
                <div className={makeLargeVal ? "msg-modal-container-lg" : 'msg-modal-container'}>
                    <div className="modal-nav-div">
                        {/* () => closeMsgModal() to invoke when pressed otherwise just closeMsgModal will invoke it immedietly */}
                        <button data-tooltip-id="tooltip-close-msg-modal" data-tooltip-content="close" data-tooltip-place="top" onClick={handleCloseMsgModal}>
                            <span>
                                <FontAwesomeIcon icon={faClose} size="xs"/>
                            </span>
                        </button>
                    </div>
                    <div className="modal-title">
                        <h3>{msgModalTitle}</h3>
                    </div>
                    <div className="modal-body">
                        {msgModalBody}
                    </div>
                </div>
                <Tooltip id="tooltip-close-msg-modal" style={{ backgroundColor: "#313131", color: "#fff", fontSize: '9px', padding: '0.3rem' }} />
            </div>
        )
    );
}
