import FetchData from "../../common/FetchData";
import { GET_VARIANT } from "../../common/ApiEndpoints";
import { useState, useEffect } from "react";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { UseCartContext } from "./CartContext";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { LoadingSub } from "../../common/Loading";

export default function CartItem({ id }) {
  const { handleOpenMsgModal } = UseMsgModalContext();
  const {
    getCartItemQty,
    increaseCartQty,
    decreaseCartQty,
    removeFromCart,
    cartItems,
  } = UseCartContext();

  let cartItemQty = getCartItemQty(id);
  let stockCount = 0;
  let available = false;

  const [variantDetails, setVariantDetails] = useState(null);

  let defaultProductImg =
    process.env.PUBLIC_URL + "/images/no-image-product.png";

  const [productImg, setProductImg] = useState(defaultProductImg);

  //   set the 1st image from the image set if available
  const handleSetPrductImg = (data) => {
    const imgSet = data.product["image_set"];
    if (imgSet.length > 0) {
      setProductImg(imgSet[0].product_image);
    }
  };

  const handleRemoveFromCart = () => {
    if (variantDetails) {
      removeFromCart(variantDetails.id);

      toast.info(
        `${variantDetails.name} SKU:${variantDetails.sku} removed from cart.`
      );
    }
  };

  const url = GET_VARIANT + id;
  const isList = false;

  const { data, loading, error } = FetchData(url, isList, {});

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setVariantDetails(data);
      handleSetPrductImg(data);
    }
  }, [data]);

  const [variantTotalPrice, setVariantTotalPrice] = useState(0);

  const getNewVariantTotalPrice = (unitPrice) => {
    return cartItemQty * unitPrice;
  };

  useEffect(() => {
    if (variantDetails) {
      const { price } = variantDetails;
      const newVariantTotalPrice = getNewVariantTotalPrice(price);
      setVariantTotalPrice(newVariantTotalPrice);
    }
  }, [variantDetails, cartItemQty, cartItems]);

  useEffect(() => {
    if (error) {
      handleOpenMsgModal({ title: "Error", body: error.message });
    }
  }, [error, handleOpenMsgModal]);

  if (loading) {
    return <LoadingSub />;
  }

  if (variantDetails) {
    stockCount = variantDetails.get_total_stock_count ?? 0;
    available = variantDetails.available;
  }

  return (
    <>
      {variantDetails && (
        <div className="highlighted-section">
          <div className="row my-2">
            <div className="col col-4">
              <img
                className="cart-item-img"
                alt="product"
                src={productImg}
              ></img>
            </div>
            <div className="col col-3 text-start d-flex align-items-center">
              <div>
                <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                  {variantDetails.product.name}
                </h3>
                <p className="font-medium text-neutral-500 mb-0">
                  <strong>SKU:</strong> {variantDetails.sku}
                </p>
                <p className="font-medium text-neutral-500 mb-0">
                  <strong>Unit Price:</strong> {variantDetails.price}
                </p>
              </div>
            </div>
            <div className="col col-3 text-start d-flex align-items-center">
              <button
                disabled={
                  stockCount === 0 || !available || cartItemQty === 0
                    ? true
                    : false
                }
                data-tooltip-id="tooltip-remove"
                data-tooltip-content="deduct"
                data-tooltip-place="left"
              >
                <FontAwesomeIcon
                  icon={faMinus}
                  onClick={() => decreaseCartQty(variantDetails.id)}
                />
              </button>
              {cartItemQty}
              <button
                disabled={
                  stockCount === 0 || !available || cartItemQty === stockCount
                    ? true
                    : false
                }
                data-tooltip-id="tooltip-add"
                data-tooltip-content="add"
                data-tooltip-place="right"
              >
                <FontAwesomeIcon
                  icon={faAdd}
                  onClick={() => increaseCartQty(variantDetails)}
                />
              </button>
            </div>
            <div className="col col-2 text-start d-flex align-items-center">
              <h3 className="text-sm mb-0 font-semibold text-neutral-900">
                ${variantTotalPrice}
              </h3>
              <button
                data-tooltip-id="tooltip-trash"
                data-tooltip-content="remove"
                data-tooltip-place="right"
                onClick={handleRemoveFromCart}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
          <Tooltip
            id="tooltip-add"
            style={{
              backgroundColor: "#313131",
              color: "#fff",
              fontSize: "9px",
              padding: "0.3rem",
            }}
          />
          <Tooltip
            id="tooltip-remove"
            style={{
              backgroundColor: "#313131",
              color: "#fff",
              fontSize: "9px",
              padding: "0.3rem",
            }}
          />
          <Tooltip
            id="tooltip-trash"
            style={{
              backgroundColor: "#313131",
              color: "#fff",
              fontSize: "9px",
              padding: "0.3rem",
            }}
          />
        </div>
      )}
    </>
  );
}
