import axios from 'axios';

const PostData = async (url, payload, headers, Queryparams) => {    
    if (Queryparams === null) {
        Queryparams = {}
    }  
    if (headers === null) {
        headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    }      
    try {        
        const response = await axios.post(url, payload, {headers: headers, params: Queryparams});  
        return { success: true, data: response }
    } catch (error) {
        // Handle errors here            
        return { success: false, error: error.response.data }
    }

}

export default PostData