import { useEffect, useState, useRef } from "react";
import FetchData from "../../common/FetchData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleUp,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { PRODUCT_LIST } from "../../common/ApiEndpoints";
import { LoadingMain, LoadingSub } from "../../common/Loading";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import ProductTile from "./ProductTile";
import ProductDetails from "./ProductDetails";
import Search from "./Search";
import Filter from "./Filter";
import FilterWithoutCollapse from "./FilterWithoutCollapse";

export default function ProductList({ unAuthorizedFetch = false }) {
  const { handleOpenMsgModal } = UseMsgModalContext();
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [productData, setProductData] = useState([]);
  const [productCount, setProductCount] = useState(0);

  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0); // Save scroll position
  const [nextPage, setNextPage] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  // filter:
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const [searchValue, setSearchValue] = useState(null);
  const [categoryFilterList, setCategoryFilterList] = useState([]);

  const [url, setUrl] = useState(PRODUCT_LIST);
  const isList = false;

  const queryParams = {
    search_value: searchValue,
    category_filter_list: categoryFilterList,
  };

  const { data, loading, error, refetchData } = FetchData(
    url,
    isList,
    queryParams,
    unAuthorizedFetch
  );

  const handleSetShowProductDetails = (productId) => {
    setShowProductDetails(true);
    setSelectedProductId(productId);
  };

  const handleSetHideProductDetails = () => {
    setShowProductDetails(false);
  };

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollPosition = container.scrollTop;
      setScrollPosition(scrollPosition); // Save current scroll position
      const maxScroll = container.scrollHeight - container.clientHeight;

      if (Math.abs(scrollPosition - maxScroll) <= 5) {
        // Scrolling down to the bottom, load more data if available
        if (nextPage) {
          setUrl(nextPage);
          setShowLoading(true);
        }
      }
    }
  };

  // instead of directly calling refetchData we wrap it inside a function with a condition
  // to make sure url is reset before calling refetchData, because url may be set to next url.
  const triggerRefetch = () => {
    // Reset the URL to the initial product list endpoint
    setUrl(PRODUCT_LIST);
    // If the URL is already PRODUCT_LIST, manually trigger the data refetch
    if (url === PRODUCT_LIST) {
      refetchData();
    }
  };

  // search
  useEffect(() => {
    setNextPage(null);
    setUrl(PRODUCT_LIST);
    setProductData([]);
    setShowLoading(true);
    triggerRefetch();
  }, [searchValue, categoryFilterList]);

  const performReload = () => {
    // when search value is changed it will trigger the useEffect func
    setSearchValue(null);
  };

  useEffect(() => {
    if (data) {
      setProductCount(data.count);
    }
    if (data && data.results && data.results.length > 0) {
      // Filter out data with duplicate ids, because product data may contain remianing ids
      const filteredData = data.results.filter(
        (result) => !productData.some((item) => item.id === result.id)
      );

      setProductData((prevProductData) => prevProductData.concat(filteredData));

      data.next ? setNextPage(data.next) : setNextPage(null);

      // Restore scroll position
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = scrollPosition;
      }
    }
    setShowLoading(false);
  }, [data]);

  useEffect(() => {
    if (error) {
      handleOpenMsgModal({ title: "Error", body: error.message });
    }
  }, [error]);

  if (loading) {
    return <LoadingMain />;
  }

  return (
    <>
      <div className="product-search-container">
        <Search
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          performReload={performReload}
        />
        <div className="product-count">
          <label className="responsive-label font-medium text-neutral-500">
            {showLoading ? "Loading" : `${productCount} Products`}
          </label>
        </div>
      </div>
      <div className="product-filter-container">
        {/* <button
          className="filter-toggle-button"
          data-tooltip-id="tooltip-filter-toggle"
          data-tooltip-content={isVisible ? "hide filter" : "show filter"}
          data-tooltip-place="right"
          onClick={toggleVisibility}
        >
          {isVisible ? (
            <FontAwesomeIcon icon={faChevronCircleUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronCircleDown} />
          )}
        </button> */}

        {/* <div
          className={`product-filter-div ${isVisible ? "visible" : "hidden"}`}
        >
          <Filter
            setCategoryFilterList={setCategoryFilterList}
            isVisible={isVisible}
          />
        </div> */}

        <div className="product-filter-div visible">
          <FilterWithoutCollapse setCategoryFilterList={setCategoryFilterList} />
        </div>
        <Tooltip
          id="tooltip-filter-toggle"
          style={{
            backgroundColor: "#313131",
            color: "#fff",
            fontSize: "9px",
            padding: "0.3rem",
          }}
        />
      </div>

      <div
        className="product-list"
        onScroll={handleScroll}
        ref={scrollContainerRef}
      >
        {showProductDetails && (
          <ProductDetails
            productId={selectedProductId}
            handleSetHideProductDetails={handleSetHideProductDetails}
            unAuthorizedFetch={unAuthorizedFetch}
          />
        )}
        {productData.length > 0 ? (
          <ul className="product-list-grid grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 flex-column">
            {productData.map((product) => (
              <div
                key={product.id}
                className="product-tile-wrapper"
                onClick={() => handleSetShowProductDetails(product.id)}
                style={{ cursor: "pointer" }}
              >
                <li>
                  <ProductTile product={product} />
                </li>
              </div>
            ))}
          </ul>
        ) : (
          <div className="text-center"></div>
        )}
      </div>
      {showLoading && <LoadingMain />}
    </>
  );
}
