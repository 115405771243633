import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faRocket,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

export default function Section2() {
  return (
    <section className="section-2">
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <div className="d-flex align-items-center justify-content-around flex-wrap">
          <div className="section-2-elem">
            <div className="d-flex justify-content-center align-items-center">
              <span className="pe-2">
                <FontAwesomeIcon color={"#1C4884"} icon={faHandHoldingDollar} />
              </span>
              <h2>Big Cashback</h2>
            </div>
          </div>
          <div className="section-2-elem">
            <div className="d-flex justify-content-center align-items-center">
              <span className="pe-2">
                <FontAwesomeIcon color={"#1C4884"} icon={faRocket} />
              </span>
              <h2>Fast Shipping</h2>
            </div>
          </div>
          <div className="section-2-elem">
            <div className="d-flex justify-content-center align-items-center">
              <span className="pe-2">
                <FontAwesomeIcon color={"#1C4884"} icon={faCreditCard} />
              </span>
              <h2>Quick Payment</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
