import { useEffect } from "react";
import "./common.css";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function DataModal({
  handleCloseDataModal,
  handleLeftBtn = null,
  title,
  children,
  btn = false,
  bodyOverflowHide = false,
  fitContentHeight = false,
  widerModal = false,
}) {
  // set classnames with classnames library:
  const dataModalContainerClasses = classNames("data-modal-container", { // default class name
    
    // conditional class names:
    "position-absolute": btn,
    "wider-data-modal-container": widerModal,
  });

  useEffect(() => {
    // Disable scrolling when the component is mounted
    document.body.style.overflow = "hidden";
    document
      .getElementsByClassName("home-container")[0]
      .classList.add("overflow-hidden");

    return () => {
      // Enable scrolling when the component is unmounted
      document.body.style.overflow = "auto";
      document
        .getElementsByClassName("home-container")[0]
        .classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div className="data-modal-background">
      <div
        className={dataModalContainerClasses}
        style={fitContentHeight ? { height: "fit-content" } : null}
      >
        <div
          className={
            handleLeftBtn
              ? "modal-nav-div d-flex justify-content-between"
              : "modal-nav-div align-items-center"
          }
        >    
          {handleLeftBtn && (
            <button
              data-tooltip-id="tooltip-back"
              data-tooltip-content="back"
              data-tooltip-place="top"
              onClick={handleLeftBtn}
            >
              <span>
                <FontAwesomeIcon icon={faArrowLeft} size="xs" />
              </span>
            </button>
          )}
          <button
            data-tooltip-id="tooltip-close"
            data-tooltip-content="close"
            data-tooltip-place="top"
            onClick={handleCloseDataModal}
          >
            <span>
              <FontAwesomeIcon icon={faClose} size="xs" />
            </span>
          </button>
        </div>
        <div className="modal-title">
          <h3>{title}</h3>
        </div>
        <div
          className={
            bodyOverflowHide ? "modal-body overflow-hidden" : "modal-body"
          }
          style={btn ? { position: "initial" } : { position: "relative" }}
        >
          {children}
        </div>
      </div>
      <Tooltip
        id="tooltip-back"
        style={{
          backgroundColor: "#313131",
          color: "#fff",
          fontSize: "9px",
          padding: "0.3rem",
        }}
      />
      <Tooltip
        id="tooltip-close"
        style={{
          backgroundColor: "#313131",
          color: "#fff",
          fontSize: "9px",
          padding: "0.3rem",
        }}
      />
    </div>
  );
}
