import { useState } from "react";
import OrderVariant from "./OrderVariant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { ORDER_CONFIRMATION_DOWNLOAD } from "../../common/ApiEndpoints";
import { UseMsgModalContext } from "../../common/MsgModalContext";
import { LoadingMain } from "../../common/Loading";
import axios from "axios";

export default function OrderItem({ order }) {
  const { handleOpenMsgModal } = UseMsgModalContext();

  const [showOrderVariants, setShowOrderVariants] = useState(false);

  const handleSetShowOrderVariants = () => {
    if (order.ordervariant_set.length > 0) {
      setShowOrderVariants(!showOrderVariants);
    }
    else {
      toast.info('No variants added to this order.')
    }
    
  };

  const [downloading, setDownloading] = useState(false);
  const accessToken = localStorage.getItem("access_token");

  const handleDownload = async () => {
    // Set the downloading state to true to show a loading indicator or disable the button
    setDownloading(true);

    // Construct the download URL using the order ID
    const url = ORDER_CONFIRMATION_DOWNLOAD + order.id;

    try {
      // Prepare the headers, including the authorization token if available
      const headers = accessToken
        ? { Authorization: `JWT ${accessToken}` }
        : {};

      // Make the API call to fetch the PDF as a blob
      // Note: responseType: 'blob' is crucial to handle binary data
      const response = await axios.get(url, { headers, responseType: "blob" });

      // Create a Blob from the response data and generate a download URL
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `OrderConfirmation-${order.transaction_id}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary link element
      document.body.removeChild(link);
    } catch (error) {
      // Log the error and show an error message in the modal
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 429) {
        handleOpenMsgModal({
          title: "Error",
          body: `${error.response.statusText}.\n ${error.response.data.detail}`,
        });
      } else {
        handleOpenMsgModal({ title: "Error", body: error.message });
      }
    } finally {
      // Set the downloading state back to false after the operation is complete
      setDownloading(false);
    }
  };

  // Why We Didn't Use the FetchData Hook:
  // The FetchData hook is designed to fetch data as soon as the component mounts
  // or when its dependencies change. In this scenario, we want the fetch to occur
  // only when the user clicks the download button. Using the hook would trigger the
  // fetch immediately on component mount, which is not the intended behavior.

  // Why the Fetch Logic is Inside the handleDownload Function:
  // - User-Triggered Action: The fetch logic is placed inside the handleDownload function to
  // ensure it runs only in response to the user's action (clicking the button).
  // - Avoiding Unnecessary API Calls: By triggering the fetch only on button click, we avoid
  // unnecessary API calls when the component mounts, which can save bandwidth and reduce server load.
  // - State Management: Managing the downloading state within the function allows us to provide
  // immediate feedback to the user, such as showing a loading indicator or disabling the button during the download process.

  // This approach ensures that the API call is made at the right time (when the user wants to download the file)
  // and provides a better user experience by managing the loading state effectively.

  return (
    <div className="highlighted-section">
      <div className="d-flex container justify-content-between">
        <div className="text-start">
          <h3 className="text-sm mb-0 font-semibold text-neutral-900">
            <strong>Transaction ID:</strong> {order.transaction_id}
          </h3>
          <small className="text-neutral-500">Placed: {order.date}</small>
          <br />
          <small className="text-neutral-500">Placed by: {order.user}</small>
        </div>
        <div className="text-start">
          <p className="mb-0 text-neutral-500">
            <small>Payment: {order.payment_method}</small>
          </p>
          <p className="mb-0 text-neutral-500">
            <small>Status: {order.status}</small>
          </p>
        </div>
      </div>
      <div>
        <div className="text-end">
          <button
            type="button"
            data-tooltip-id="tooltip-download"
            data-tooltip-content="download"
            data-tooltip-place="right"
            onClick={handleDownload}
            disabled={downloading}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
          <button
            type="button"
            onClick={handleSetShowOrderVariants}
            data-tooltip-id="tooltip-order-variant-collapse"
            data-tooltip-content={
              showOrderVariants ? "hide variants" : "show variants"
            }
            data-tooltip-place="top"
          >
            <FontAwesomeIcon
              icon={showOrderVariants ? faChevronCircleUp : faChevronCircleDown}
            />
          </button>
        </div>
        <div
          className={`order-variants ${
            showOrderVariants ? "visible" : "hidden"
          }`}
        >
          {order.ordervariant_set.length > 0 && order.ordervariant_set.map((orderVariant) => (
            <OrderVariant key={orderVariant.id} orderVariant={orderVariant} />
          ))}
        </div>
      </div>

      <div className="text-end">
        {downloading && <LoadingMain />}
        <h3 className="text-sm mb-0 font-semibold text-neutral-900">
          Total: ${typeof order.total_price_after_discount === 'number' ? order.total_price_after_discount.toFixed(2) : 0.00}
        </h3>
      </div>
      <Tooltip
        id="tooltip-download"
        style={{
          backgroundColor: "#313131",
          color: "#fff",
          fontSize: "9px",
          padding: "0.3rem",
        }}
      />
      <Tooltip
        id="tooltip-order-variant-collapse"
        style={{
          backgroundColor: "#313131",
          color: "#fff",
          fontSize: "9px",
          padding: "0.3rem",
        }}
      />
    </div>
  );
}
