import Heading from "./heading-components/Heading";
import { useState } from "react";
import Profile from "./profile-components/Profile";
import ProductList from "./product-components/ProductList";
import { CartProvider } from "./cart-components/CartContext";
import Cart from "./cart-components/Cart";
import Order from "./order-components/Order";

export default function Dashboard() {
  // profile:
  const [showProfile, setShowProfile] = useState(false);
  // order:
  const [showOrder, setShowOrder] = useState(false)
  
  const handleSetShowProfile = () => {
    setShowProfile(!showProfile);
  };

  const handleSetShowOrder = () => {
    setShowOrder(!showOrder)
  }

  return (
    <CartProvider>
      <div className="home-container">
        <Heading title={"Home"} handleSetShowProfile={handleSetShowProfile} handleSetShowOrder={handleSetShowOrder}/>
        {showProfile && <Profile setShowProfile={setShowProfile} />}
        {showOrder && <Order setShowOrder={setShowOrder}/>}
        <ProductList unauthorizedFetch={false} />
        <Cart />
      </div>
    </CartProvider>
  );
}
