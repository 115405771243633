import axios from 'axios';

const SimpleFetch = async (url, Queryparams) => {
    const accessToken = localStorage.getItem('access_token')   
    if (Queryparams === null) {
        Queryparams = {}
    } 
    try {
        const headers = { 'Authorization': `JWT ${accessToken}` }
        const response = await axios.get(url, { headers: headers, params: Queryparams });
        const data = response.data;            
        return {success: true, data: data}
        
    } catch (error) {    
        if (error.response && error.response.status === 429) {
            error.message = `${error.response.statusText}.\n ${error.response.data.detail}`;
        }
        return {success: false, error: error}        
    }
};

export default SimpleFetch