import { createContext, useContext, useState } from "react";

const MsgModalContext = createContext();

export const UseMsgModalContext = () => useContext(MsgModalContext)

export const MsgModalProvider = ({ children }) => {
    const [openMsgModal, setOpenMsgModal] = useState(false);
    const [msgModalTitle, setMsgModalTitle] = useState('')
    const [msgModalBody, setMsgModalBody] = useState('')
    const [makeLargeVal, setMakeLargeVal] = useState(false)

    const handleOpenMsgModal = ({ title, body, makeLarge = false }) => {
        setOpenMsgModal(true)
        setMsgModalTitle(title)
        setMsgModalBody(body)
        makeLarge && setMakeLargeVal(true)
    }
    
    const handleCloseMsgModal = () => {
        setOpenMsgModal(false)
        setMakeLargeVal(false)
    }

    const contextValue = { openMsgModal, handleOpenMsgModal, handleCloseMsgModal, msgModalTitle, msgModalBody, makeLargeVal }

    return (
        <MsgModalContext.Provider value={contextValue}>
            {children}
        </MsgModalContext.Provider>
    );
};

